<template>
  <div>
    <van-nav-bar :title="$route.meta.title" left-text="" left-arrow @click-left="onClickNavLeft" :fixed="true"
      :placeholder="true" z-index="999" style="background:#05B252;">
      <template #left>
        <img src="@/assets/img/fanhuibai.png" alt="" class="navFanhui imgFit">
      </template>
      <template #right>
        <div class="mine_box" @click="tomine">
          <img src="@/assets/img/mineLv.png" alt="">
          <div>我的</div>
        </div>
      </template>
    </van-nav-bar>
    <div class="bj"></div>
    <div class="outer" v-if="list.length > 0">
      <div class="contOne">
        <div class="oneNum">{{ current + 1 }}/{{ list.length }}</div>
        <img src="@/assets/img/biao.png" alt="">
        <van-count-down class="oneTime" ref="countDown" :time="time" @finish="onFinish" />
      </div>
      <div>
        <div class="contTitle">{{ list[current].content }}</div>
        <div class="option">
          <!-- :class="item.isclick == true ? item.isexactness == true ? 'itemZQ' : item.isexactness == false ? 'itemCU' : 'itemBian' : item.isexactness == true ? 'itemZQ' : item.isexactness == false ? 'itemCU' : ''" -->
          <div class="optionItem"
            :class="item.isexactness == true ? 'itemZQ' : item.isexactness == false ? 'itemCU' : ''"
            :id="'item' + index" v-for="(item, index) in list[current].options" :key="index"
            @click="addBtnFlag && answerItem(list[current], item, current, index, item.key)" v-throttle>
            <div class="itemKey">{{ item.key }}</div>
            <div class="itemTitle">{{ item.value }}</div>
          </div>
        </div>
        <div class="congFour" v-if="exactness == true">正确答案：{{ list[current].answer }}</div>
      </div>
      <div style="height:90px"></div>
      <div class="congFive">
        <div v-if="exactness == true && (this.current + 1) < this.list.length">
          <div class="fiveOne" @click="again" v-throttle>重新答题</div>
          <div class="fiveTwo" @click="continues" v-throttle>继续答题</div>
        </div>
        <!-- <div v-if="exactness == false && (this.current + 1) < this.list.length">
          <div class="fiveTwo" @click="nextQuestion" v-throttle>下一题</div>
        </div> -->
        <div v-if="lastBtn == true">
          <div class="fiveTwo" @click="submit" v-throttle>提交</div>
        </div>
      </div>
    </div>
    <!-- 答完交卷 -->
    <van-popup v-model="isOver" :close-on-popstate="true" :close-on-click-overlay="false" round position="bottom">
      <div class="popup_cont">
        <div class="popup_tip_one">恭喜您 全部答对 </div>
        <div class="popup_tip_one">您将获取一次抽取{{info.answer_money}}元答题奖励的机会 !</div>
        <div class="popup_tip_two">请关注 <span>“石家庄林业”</span> 官方公众号及您个人信息。感谢您对本次活动的支持！</div>
        <img src="@/assets/img/answer1.png" alt="" class="img1">
        <div class="Answer_bonus" @click="tomains">
          <div class="Answer_bonus_left">
            <img src="@/assets/img/hongbao.png" alt="">
            <div>我的答题奖励</div>
          </div>
          <div class="Answer_bonus_right">
            <div>查看</div>
            <img src="@/assets/img/right.png" alt="">
          </div>
        </div>
        <div class="popup_three">
          <div class="popup_three_one" @click="homePage" v-throttle>返回首页</div>
          <div class="popup_three_two" @click="tomains" v-throttle>我知道了</div>
        </div>
      </div>
    </van-popup>
    <!-- 超时提醒 -->
    <van-popup v-model="isOvertime" :close-on-popstate="true" :close-on-click-overlay="false" round position="bottom">
      <div class="popup_cont">
        <div class="popup_tip_one">您已超时 请重新答题</div>
        <div class="popup_tip_two">答题时间为{{ info.answer_time / 60 }}分钟，请合理使用时间</div>
        <img src="@/assets/img/answer2.png" alt="" class="img1">

        <div class="popup_three">
          <div class="popup_three_one" @click="homePage" v-throttle>返回首页</div>
          <div class="popup_three_two" @click="again" v-throttle>重新答题</div>
        </div>
      </div>
    </van-popup>
    <!-- 答错提醒 -->
    <van-popup v-model="isMistaken" :close-on-popstate="true" :close-on-click-overlay="false" round position="bottom">
      <div class="popup_cont">
        <div class="popup_tip_one">很遗憾 答题有误</div>
        <div class="popup_tip_two">每位用户每天有{{ info.answer_chance_count }}次答题机会，可以重新答题</div>
        <img src="@/assets/img/answer3.png" alt="" class="img1">
        <div class="Answer_bonus" @click="tomains">
          <div class="Answer_bonus_left">
            <img src="@/assets/img/hongbao.png" alt="">
            <div>我的答题奖励</div>
          </div>
          <div class="Answer_bonus_right">
            <div>查看</div>
            <img src="@/assets/img/right.png" alt="">
          </div>
        </div>
        <div class="popup_three">
          <div class="popup_three_one" @click="homePage" v-throttle>返回首页</div>
          <div class="popup_three_two" @click="again" v-throttle>重新答题</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：答题
 * @author: 刘嘉鑫 
 * @version  V1.0 
 * @datecreate: 2023-03-02 16:38
 */
export default {
  inject: ["reload"], // 刷新页面
  data() {
    return {
      list: [],
      current: 0, // 正答的第几道题
      exactness: false, // 正确答案是否展示

      addBtnFlag: true, // 是否可以点击

      arr: [], // 多选选中的选项
      optionsResult: [], // 多选选中的结果

      isWrong: false, // 是否答错一题

      isOver: false, // 答完交卷

      isOvertime: false, // 超时

      isMistaken: false, // 答错

      submitList: [], // 提交的数据

      id: "", // 答题记录id
      time: "0", // 倒计时秒数

      info: "",

      selectIndex2: "", // 选中的下标2
      selectIndex22: [], // 选中的下标2
      selectkey: "",// 选中的答案
      selectkey22: [],

      lastBtn: false, // 最后一道题答错显示提交按钮
      isShow:false
    };
  },
  computed: {
    disposefn() {
      return this.$store.state.dispose;
    },
  },
  watch: {
    disposefn: {
      handler: function (val, oldval) {
        this.info = val
      },
      deep: true
    },
  },
  mounted() {
    this.getList()
    this.info = this.$store.state.dispose
  },
  methods: {
    /**
      * 我的按钮
      * 刘义博
      * 2024-4-16
    */
    tomine() {
      if (this.isOver == true || this.isMistaken == true) {
        this.$router.replace({ path: "/mine" });
        return
      }
      this.$toast({
        message: '请答题完成之后，再进行操作',
        duration: 1500
      })
    },
    /**
      * 查看红包记录
      * 刘义博
      * 2024-4-16
    */
    tomains() {
      setTimeout(() => {
        this.$router.replace({ path: "/mine" }); //跳转之后会返回到上上个页面不进入答题页面就不会消耗次数了
      }, 100)
    },
    /**
     * 获得答题列表
     * 刘嘉鑫
     * 2023-3-6
     */
    getList() {
      this.$request({
        url: "/Index/getQuestionList",
        data: {
          mobile: this.phone,
        },
      }).then((res) => {
        //将bas64数据解密
        let listbas64 = res.data.list
        let listbas64data =  window.atob(listbas64);
        let datalist = JSON.parse(listbas64data)
        this.id = res.data.exam_history_id
        this.time = res.data.answer_time * 1000
        datalist.map((item, index) => {
          item.options.map((item2, index2) => {
            item2.isclick = false
            item2.isexactness = -99
            return item2
          })
          return item
        })
        this.list = datalist
      }).catch(error => {
        if (error.code == -222) {
          this.$toast({
            message: '请勿重复操作，稍后刷新页面',
            duration: 1500
          })
        } else if (error.code == -333) {
          this.$toast({
            message: error.msg,
            duration: 2000
          })
          this.homePage()
        } else {
          this.$toast({
            message: error.msg,
            duration: 1500
          })
        }
      })
    },



    /**
     * 选项选中
     * 刘嘉鑫
     * 2023-3-2
     */
    // answerItem(list, item, index, index2, key) {
    //   this.arr.push(key)
    //   // type 1:单选 2:多选 3:判断
    //   // isclick  点击的是哪个
    //   // isexactness  哪个是正确的

    //   this.selectIndex2 = index2 // 选中的下标2
    //   this.selectkey = key // 选中的答案
    //   this.selectIndex22.push(index2) // 选中的下标2
    //   this.selectkey22.push(key) // 选中的答案

    //   if (list.type == 1 || list.type == 3) {
    //     for (let i in this.list[index].options) {
    //       this.list[index].options[i].isclick = false
    //     }
    //     this.list[index].options[index2].isclick = true
    //   } else {
    //     this.list[index].options[index2].isclick = true
    //   }

    // },
    answerItem(list, item, index, index2, key) {
      let obj = {}
      this.arr.push(key)
      // type 1:单选 2:多选 3:判断
      // isclick  点击的是哪个
      // isexactness  哪个是正确的
      if (list.type == 1 || list.type == 3) {

        if (key.toUpperCase() == list.answer.toUpperCase()) {
          this.list[index].options[index2].isexactness = true
          this.$toast({
            message: '回答正确，即将进入下一题',
            duration: 1000
          })
          setTimeout(() => {
            this.nextQuestion()
            this.addBtnFlag = true
          }, 1000);
        } else {
          for (let i in list.options) {
            if (list.options[i].key.toUpperCase() == list.answer.toUpperCase()) {
              this.list[index].options[i].isexactness = true
              this.list[index].options[index2].isexactness = false
            }
          }
          this.exactness = true
          this.isWrong = true
          if ((this.current + 1) == this.list.length) {
            this.lastBtn = true
          }
        }
        this.list[index].options[index2].isclick = true
        this.addBtnFlag = false
        obj.id = list.id
        obj.type = list.type
        obj.answer = this.arr.join(",")
        this.submitList.push(obj)
      } else {
        this.list[index].options[index2].isclick = true
        let newAnswer = list.answer.split(";")
        newAnswer = newAnswer && newAnswer.filter(i => i) || [] // 过滤空字符串
        let b = newAnswer.some((item, index) => {
          return item == key
        })
        this.list[index].options[index2].isexactness = b
        this.optionsResult.push(b)
        if (this.arr.length == newAnswer.length) {
          this.addBtnFlag = false
          let a = this.optionsResult.every((item, index) => {
            return item == true
          })
          if (a == false) {
            this.exactness = true
            this.isWrong = true
            if ((this.current + 1) == this.list.length) {
              this.lastBtn = true
            }
          } else {
            this.$toast({
              message: '回答正确，即将进入下一题',
              duration: 1000
            })
            setTimeout(() => {
              this.nextQuestion()
              this.addBtnFlag = true
            }, 1000);
          }
          obj.id = list.id
          obj.type = list.type
          obj.answer = this.arr.join(";")
          this.submitList.push(obj)
        }
      }
    },

    /**
     * 下一题
     * 刘嘉鑫
     * 2023-3-3
     */
    // nextQuestion() {
    //   if (this.selectIndex2 === "" || this.selectkey === "" || this.selectkey22.length == 0 || this.selectIndex22.length == 0) {
    //     this.$toast({
    //       message: "请选择该题答案",
    //       duration: 1500
    //     })
    //   } else {
    //     this.getToInfo()
    //     let list = this.list[this.current] // 当前答的那个题
    //     let index = this.current  // 选中的下标1
    //     let index2 = this.selectIndex2 // 选中的下标2
    //     let key = this.selectkey  // 选中的答案

    //     if (list.type == 1 || list.type == 3) {
    //       if (key == list.answer) {
    //         this.list[index].options[index2].isexactness = true
    //         ++this.current
    //         this.exactness = false
    //         this.arr = []
    //         this.selectIndex2 = "" // 选中的下标2
    //         this.selectIndex22 = [] // 选中的下标2
    //         this.selectkey = "" // 选中的答案
    //         this.selectkey22 = [] // 选中的答案
    //       } else {
    //         for (let i in list.options) {
    //           if (list.options[i].key == list.answer) {
    //             this.list[index].options[i].isexactness = true
    //             this.list[index].options[index2].isexactness = false
    //             this.exactness = true
    //             this.isWrong = true
    //           }
    //         }
    //       }
    //     } else {
    //       let newAnswer = list.answer.split(";")
    //       newAnswer = newAnswer && newAnswer.filter(i => i) || [] // 过滤空字符串
    //       let b = newAnswer.every((item, index) => {
    //         //判断的条件为a数组的每一项在b数组内查找不等于-1就代表有，有就为true，所有都是true就返回true
    //         return this.selectkey22.indexOf(item) !== -1;
    //       });
    //       if (this.arr.length == newAnswer.length) {
    //         for (let a in this.selectIndex22) {
    //           this.list[index].options[this.selectIndex22[a]].isexactness = false
    //         }
    //         if (b == false) {
    //           this.exactness = true
    //           this.isWrong = true
    //         } else {
    //           ++this.current
    //           this.exactness = false
    //           this.arr = []
    //           this.selectIndex2 = "" // 选中的下标2
    //           this.selectIndex22 = [] // 选中的下标2
    //           this.selectkey = "" // 选中的答案
    //           this.selectkey22 = [] // 选中的答案
    //         }
    //       }
    //     }
    //   }
    // },

    getToInfo() {
      let obj = {}
      let list = this.list[this.current] // 当前答的那个题
      if (list.type == 1 || list.type == 3) {
        obj.id = list.id
        obj.type = list.type
        obj.answer = this.selectkey
      } else {
        obj.id = list.id
        obj.type = list.type
        obj.answer = this.selectkey22.join(",")
      }
      this.submitList.push(obj)
    },

    nextQuestion() {
      if ((this.current + 1) == this.list.length) {
        this.submit()
      } else {
        ++this.current
        this.exactness = false
        this.addBtnFlag = true
        this.arr = []
      }
    },

    /**
     * 继续答题
     * 刘嘉鑫
     * 2023-3-3
     */
    continues() {
      // if ((this.current + 1) == this.list.length) {
      //   this.submit()
      // } else {
      ++this.current
      this.exactness = false
      this.addBtnFlag = true
      this.arr = []
      // this.selectList = "" // 当前答的那个题
      // this.selectItem = "" // 选中的选项
      // this.selectIndex = "" // 选中的下标1
      // this.selectIndex2 = "" // 选中的下标2
      // this.selectkey = "" // 选中的答案
      // }
    },

    /**
     * 返回首页
     * 刘嘉鑫
     * 2023-3-3
     */
    homePage() {
      this.onClickNavLeft()
    },

    /**
     * 提交
     * 刘嘉鑫
     * 2023-3-6
     */
    submit() {
      // this.getToInfo()
      if(this.isShow == true)return
      if ((this.current + 1) < this.list.length) {
        this.$toast({
          message: "请答完所有题再提交",
          duration: 1500
        })
      }
      this.isShow = true
      this.$request({
        url: "/Index/addExamHistory",
        data: {
          exam_history_id: this.id,
          answer_list: JSON.stringify(this.submitList)
        },
      }).then((res) => {
        setTimeout(() => {
          this.isShow = false
        },2000)
        // answer_status 为1时代表全对，为0时代表有错误的
        if (res.data.answer_status == 0) {
          this.isMistaken = true
        }
        if (res.data.answer_status == 1) {
          this.isOver = true
        }
      }).catch(error => {
        setTimeout(() => {
          this.isShow = false
        },2000)
        if (error.code == -111) {
          this.isOvertime = true
        }
        if (error.code == -333) {
          this.$toast({
            message: "您的答题次数已用完",
            duration: 1500
          })
        } else {
          this.$toast({
            message: error.msg,
            duration: 1500
          })
        }
      })
    },

    /**
     * 重新答题
     * 刘嘉鑫
     * 2023-3-6
     */
    again() {
      this.reload();
    },

    /**
     * 倒计时
     * 刘嘉鑫
     * 2023-3-6
     */
    onFinish() {
      console.log('倒计时结束')
      if (this.list.length > 0) {
        this.isOvertime = true
        this.submit()
      }
    },

    /**
     * 返回上一页
     * 刘嘉鑫
     * 2022-11-27
     */
    onClickNavLeft() {
      this.$router.go(-1)
    },
  },
};
</script>
<style lang='scss' scoped>
:deep(.van-nav-bar__title) {
  color: #ffffff !important;
  margin: 0 auto 0 40px !important;
  font-size: 19px !important;
}

:deep(.van-nav-bar) {
  background: #05B252 !important;
  border: none !important;
  position: fixed !important;
}

:deep(.van-hairline--bottom::after) {
  border: none !important;
}

:deep(.van-nav-bar__content) {
  height: 56px !important;
}

.bj {
  width: 100%;
  height: 200px;
  background: #05B252 !important;
  margin-top: -1px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.outer {
  position: relative;
  background: #FFFFFF;
  border-radius: 60px 60px 0px 0px;
  padding: 30px 36px;
  min-height: 300px;
}

.contOne {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .oneNum {
    background: #DBFFEB;
    border-radius: 60px;
    padding: 10px 22px;
    font-size: 32px;
    font-weight: bold;
    color: #05B252;
  }

  img {
    width: 32px;
    height: 32px;
    margin-left: auto;
    margin-bottom: 2px;
  }

  .oneTime {
    font-size: 32px;
    font-weight: bold;
    color: #05B252;
    margin-left: 10px;
  }
}

.contTitle {
  font-size: 30px;
  font-weight: 400;
  color: #222222;
  line-height: 60px;
  margin-bottom: 60px;
}

.option {
  margin-bottom: 20px;

  .optionItem {
    background: #F2F2F2;
    border-radius: 60px;
    padding: 16px 20px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    border: 2px solid transparent;

    .itemKey {
      width: 76px;
      height: 76px;
      text-align: center;
      line-height: 76px;
      font-size: 32px;
      font-weight: bold;
      color: #444444;
      margin-right: 33px;
      border-radius: 50%;
    }

    .itemTitle {
      font-size: 30px;
      font-weight: 400;
      color: #444444;
      width: 81%;
    }
  }

  .itemBian {
    border: 2px solid #05B252 !important;
  }

  .itemZQ {
    background: rgba(5, 178, 82, .1) !important;
    border: 2px solid transparent !important;

    .itemKey {
      background: #05B252 !important;
      color: #ffffff !important;
    }
  }

  .itemCU {
    background: rgba(213, 70, 94, .1) !important;
    border: 2px solid transparent !important;

    .itemKey {
      background: #D5465E !important;
      color: #ffffff !important;
    }
  }
}

.congFour {
  font-size: 32px;
  font-weight: bold;
  color: #05B252;
}

.congFive {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;

  >div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 36px 40px;

    >div {
      flex: 1;
      padding: 28px 0;
      text-align: center;
      border-radius: 60px;
      font-size: 34px;
      font-weight: bold;
    }

    .fiveOne {
      background: #EFEFEF;
      color: #222222;
      margin-right: 36px;
    }

    .fiveTwo {
      background: #05B252;
      color: #ffffff;
    }
  }
}

.popup_cont {
  padding: 60px 64px;

  .popup_tip_one {
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: bold;
    color: #222222;
    text-align: center;
    letter-spacing: .1em;
  }

  .popup_tip_two {
    margin: 20px 0 57px;
    font-size: 28px;
    font-weight: 400;
    color: #666666;
    line-height: 42px;
    text-align: center;

    span {
      color: #05B252 !important;
      font-weight: bold !important;
    }
  }

  .img1 {
    margin: 0 auto 67px;
    width: 180px;
    height: 180px;
  }

  .popup_three {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    >div {
      border-radius: 60px;
      width: 47%;
      text-align: center;
      font-size: 34px;
      font-weight: bold;
      padding: 32px 0;
    }

    .popup_three_one {
      background: #EFEFEF;
      color: #222222;
    }

    .popup_three_two {
      background: #05B252;
      color: #FFFFFF;
    }

  }
}

.mine_box {
  // position: absolute;
  // top: 29px;
  // right: 0;
  width: 140px;
  height: 60px;
  background: #FFFFFF;
  border-radius: 30px 0px 0px 30px;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  margin-right: -35px;

  img {
    width: 29px;
    height: 28px;
    margin-right: 8px;
  }

  div {
    font-weight: bold;
    font-size: 26px;
    color: #05B252;
  }
}

.Answer_bonus {
  padding: 24px 39px;
  height: 80px;
  background: #F6F6F6;
  border-radius: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .Answer_bonus_left {
    display: flex;
    align-items: center;


    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
      margin-bottom: -2px;
    }

    div {
      font-weight: bold;
      font-size: 26px;
      color: #222222;
    }
  }

  .Answer_bonus_right {
    display: flex;
    align-items: center;

    div {
      font-weight: 400;
      font-size: 26px;
      color: #666666;
      margin-right: 10px;
    }

    img {
      width: 18px;
      height: 25px;
    }
  }
}
</style>